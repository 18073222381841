<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">Data Ingestion Report</h4>
      <p class="category">
        In this page you will be able to see a full log of import and report
        tries and thier results.
      </p>
    </div>
    <div class="col-12">
      <card title="Log entries">
        <div class="row">
          <pg-table
            :tableColumns="PgTable.tableColumns"
            :tableData="PgTable.tableData"
            :expandedTableColumns="PgTable.expandedTableColumns"
            :expandedTableData="PgTable.expandedTableData"
            :propsToSearch="PgTable.propsToSearch"
            :handleCurrentChange="PgTable.handleCurrentChange"
            :withStatus="true"
            :expandable="true"
            :withDateTimeRange="true"
            :paginated="true"
            :searchable="true"
            ref="pgTable"
          >
          </pg-table>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import PgTable from "src/pages/Dashboard/Tables/PaginatedTables.vue";
import DatetimeFormatter from "src/pages/Dashboard/Components/Formatters/DatetimeFormatter.vue";
import moment from "moment";
import { initAPI } from "src/gateway/api-instance.js";

export default {
  components: {
    PgTable,
    DatetimeFormatter
  },
  computed: {},
  data() {
    return {
      PgTable: {
        tableColumns: [
          {
            prop: "time",
            label: "Time",
            minWidth: 100,
            formatter: (row, col, cell, index) => this.dateformat(row, col),
            _formatter: this.dateformat
          },
          {
            prop: "customer_NAME",
            label: "Customer",
            minWidth: 75
          },
          {
            prop: "venue_NAME",
            label: "Venue",
            minWidth: 75
          },
          {
            prop: "pos",
            label: "POS System",
            minWidth: 75
          },
          {
            prop: "operation",
            label: "Operation",
            minWidth: 100
          },
          {
            prop: "error",
            label: "Error",
            minWidth: 250
          }
        ],
        expandedTableColumns: [
          {
            prop: "id",
            label: "ID",
            minWidth: 50
          },
          {
            prop: "venue_ID",
            label: "Venue",
            minWidth: 75
          },
          {
            prop: "plu_CODE",
            label: "PLU Code",
            minWidth: 75
          },
          {
            prop: "description",
            label: "Description",
            minWidth: 100
          },
          {
            prop: "price",
            label: "Price",
            minWidth: 50,
            formatter: (row, col, cell, index) => this.priceFormate(row, col)
          },
          {
            prop: "quantity",
            label: "Quantity",
            minWidth: 50
          },
          {
            prop: "time",
            label: "Time",
            minWidth: 100,
            formatter: (row, col, cell, index) => this.dateformat(row, col)
          },
          {
            prop: "cost_CENTER",
            label: "Cost Center",
            minWidth: 75
          },
          {
            prop: "status",
            label: "Status",
            minWidth: 50
          }
        ],
        tableData: [],
        expandedTableData: [
          {
            ID: "1",
            venue_id: "1",
            plu_code: "1",
            description: "1",
            price: "30.666",
            time_stamp: "2021-01-14T16:28:23.399918",
            cost_center: "1",
            status: "1"
          }
        ],
        propsToSearch: ["venue", "customer_ID", "operation", "status"],
        handleCurrentChange: this.expandRow
      },
      expanded: false
    };
  },
  computed: {
    Instance() {
      return initAPI(this.$cookies.get("Token"), process.env.VUE_APP_API_URL);
    }
  },
  methods: {
    getLogData() {
      this.Instance.get("/log").then(response => {
        this.PgTable.tableData = response.data;
        console.log(this.PgTable.tableData);
      });
    },
    getOrderData(from, to) {
      // put the data in this.PgTable.expandedTableData
      var dates = { start: from, end: to };
      console.log(JSON.stringify(dates));
      this.Instance.post("/ordersDated", dates).then(response => {
        console.log(response.data);
        this.PgTable.expandedTableData = response.data;
      });
    },
    expandRow(row) {
      if (row.status == "Success") {
        this.expanded = !this.expanded;
        this.getOrderData(row.from_TIME, row.time);
        this.$refs.pgTable.$refs.tableData.toggleRowExpansion(
          row,
          this.expanded
        );
      }
    },
    dateformat: function(row, column) {
      var date = row[column.property];
      if (date == undefined) {
        return "";
      }
      return moment(date).format("DD-MM-YYYY HH:mm:ss");
    },
    priceFormate: function(row, column) {
      var price = row[column.property];
      return Number(price / 1).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        style: "currency",
        currency: "USD"
      });
    }
  },
  filters: {},
  mounted() {
    this.getLogData();
  }
};
</script>
<style></style>
