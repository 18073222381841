<template functional>
  <span>
    <i class="icon-date"></i> {{ props.row[props.column] }}
  </span>
</template>

<script>
  export default {
    name: 'DatetimeFormatter',
  }
</script>